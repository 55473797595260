import React, { useState } from "react";
import { Button, Alert, Collapse } from "react-bootstrap";
import I18n from "i18n-js";

import InternalModal from "../../../commons/InternalModal";

const DeleteTaskConfirmation = ({ task, show, onCancel, onConfirm }) => {

  const [loading, setLoading] = useState(false);

  const deleteTask = async () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      onConfirm && onConfirm();
    }, 1000);
  }

  const reset = () => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const handleConfirm = async () => {
    await deleteTask();
  }

  const handleCancel = () => {
    onCancel && onCancel();
    reset();
  }

  const renderConfirmation = () => {
    return (
      <Alert
        show
        variant="danger"
        transition={Collapse}
        className="mb-0"
      >
        <Alert.Heading>
          {I18n.t("pdp.confirmations.delete_task.title")}
        </Alert.Heading>
        <br/>
        <p>{I18n.t("pdp.confirmations.delete_task.content")}</p>
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-secondary"
            disabled={loading}
            onClick={handleCancel}
          >
            {I18n.t("common.cancel")}
          </Button>
          <Button
            variant="outline-danger"
            disabled={loading}
            onClick={handleConfirm}
          >
            {I18n.t("common.continue")}
          </Button>
        </div>
      </Alert>
    );
  }

  return (
    <InternalModal
      type="delete-task-confirmation"
      size="md"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderConfirmation()}
      showModalBody={false}
    />
  );
}

export default DeleteTaskConfirmation;
