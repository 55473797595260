import React, { Fragment, useEffect, useState } from "react";
import _ from "lodash";

import { MAX_OBJECTIVES } from "../../config/constants";

import MultipleActionButton from "../../../commons/MultipleActionButton";

import CopyItemModal from "../Modal/CopyItemModal";
import Objective, { defaultTask } from "./Objective";

import plusIcon from "../../../assets/icons/plus.svg"

const defaultObjective = (index) => ({
  index: index,
  title: "",
  tasks: [defaultTask(1)]
});

const ObjectiveList = ({ errors, onChange, onErrorClear }) => {

  const [itemType, setItemType] = useState("objective");
  const [showItemPicker, setShowItemPicker] = useState(false);
  const [objective, setObjective] = useState(null);

  const [objectives, setObjectives] = useState([defaultObjective(1)]);

  useEffect(() => {
    onChange && onChange(objectives);
  }, [objectives]);

  const showCopyItemPicker = (type) => {
    setItemType(type);
    setShowItemPicker(true);
  }

  const getNextIndex = (items = objectives) => {
    return _.maxBy(items, item => item.index).index + 1;
  }

  const handleObjectiveChange = (objective) => {
    const newObjectives = objectives.map(obj => obj.index === objective.index ? objective : obj);
    setObjectives(newObjectives); 
  }

  const handleObjectiveRemove = (objective) => {
    handleObjectiveChange({ ...objective, unmounting: true });

    setTimeout(() => {
      const newObjectives = objectives
        .filter(obj => obj.index !== objective.index)
        .map((obj, index) => ({ ...obj, index: index + 1 }));
      setObjectives(newObjectives);
    }, 300);
  }

  const handleCopyExistingTaskClick = (objective) => {
    setObjective(objective);
    showCopyItemPicker("task");
  }

  const handleCopyExistingObjectiveClick = () => {
    showCopyItemPicker("objective");
  }

  const handleCreateNewObjectiveClick = () => {
    const index = getNextIndex();
    setObjectives(obj => [...obj, defaultObjective(index)]);
  }

  const handleItemSelect = (item) => {
    setShowItemPicker(false);

    if (itemType === "task") {
      const obj = objective;

      const task = {
        ..._.pick(item, ["title"]),
        index: getNextIndex(obj.tasks),
        target_date: ""
      };

      obj.tasks = [...obj.tasks, task];
      handleObjectiveChange(obj);
    } else {
      const objective = {
        index: getNextIndex(),
        title: item.title,
        tasks: item.tasks.map(task => _.pick(task, ["index", "title"]))
      };
      setObjectives(obj => [...obj, objective]);
    }
  }

  const handleErrorClear = (key, index) => {
    onErrorClear && onErrorClear(`objectives[${index}].${key}`)
  }

  return (
    <Fragment>
      <div className="objective-list">
        <div className="objectives">
          {objectives.map((objective, index) => (
            <Objective
              key={index}
              objective={objective}
              showRemove={objectives.length > 1}
              unmounting={objective.unmounting || false}
              errors={errors && errors[index]}
              onChange={handleObjectiveChange}
              onRemove={handleObjectiveRemove}
              onErrorClear={key => handleErrorClear(key, index)}
              onCopyExistingTaskClick={handleCopyExistingTaskClick}
            />
          ))}
        </div>
        <MultipleActionButton
          title="Add Objective"
          icon={plusIcon}
          actions={[
            {
              title: "Copy existing",
              onClick: handleCopyExistingObjectiveClick
            },
            {
              title: "Create new",
              onClick: handleCreateNewObjectiveClick
            }
          ]}
          disabled={objectives.length >= MAX_OBJECTIVES}
        />
        <div className="pdp-form-error">{errors?.message}</div>
      </div>
      <CopyItemModal
        show={showItemPicker}
        type={itemType}
        onSelect={handleItemSelect}
        onCancel={() => setShowItemPicker(false)}
      />
    </Fragment>
  );
}

export default ObjectiveList;
