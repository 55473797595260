import React, { useEffect, useRef } from "react";

const ProgressBar = ({ value, larger }) => {

  const progressRef = useRef();

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.width = value + "%";
      progressRef.current.style.borderRadius = "50px";
    }
  }, [value]);

  return (
    <div className={`progress-bar ${larger ? "larger" : ""}`} title={`${value}%`}>
      <div ref={progressRef} className="progress" />
    </div>
  );
}

export default ProgressBar;
