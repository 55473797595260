import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import _ from "lodash";

import Editor, { EmptyState } from "../../../../commons/Editor";

import Header from "./Header";

const NoteEditor = ({ task }) => {

  const [note, setNote] = useState(null);

  const [loading, setLoading] = useState(false);
  const [showSave, setShowSave] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const content = useRef();

  useEffect(() => {
    setNote(task?.note);
    reset();
  }, [task]);

  const saveNote = () => {
    setLoading(true);

    const data = { content: content.current };

    setTimeout(() => {
      toast.success(<b>{I18n.t("common.saved_successfully")}</b>);
    }, 500);
  }

  const reset = () => {
    setLoading(false);
    setShowSave(false);
    setCanSave(false);
    content.current = null;
  }

  const handleOnContentChange = (value) => {
    content.current = JSON.stringify(value);

    const isNotEmpty = !_.isEqual(value, EmptyState);
    const hasSomeChange = !note || content.current !== note.content;

    if (!showSave && hasSomeChange) { setShowSave(true); }
    
    const save = isNotEmpty && hasSomeChange;
    if (canSave !== save) { setCanSave(save); }
  }

  const handleOnSaveClick = () => {
    const noteContent = content.current;

    if (_.isEmpty(noteContent) ||
        _.isNull(noteContent) ||
        _.isUndefined(noteContent)) {
      return;
    }
    
    saveNote();
  }

  return (
    <div className="note-editor">
      <Header
        showSave={showSave}
        canSave={canSave}
        loading={loading}
        onSaveClick={handleOnSaveClick}
      />
      <Editor
        note={note}
        onChange={handleOnContentChange}
      />
    </div>
  );
}

export default NoteEditor;
