import React, { useEffect, useState } from "react";
import moment from "moment";
import I18n from "i18n-js";

import InternalModal from "../../../commons/InternalModal";
import ActionButton from "../../../commons/ActionButton";
import DateInput from "../../../commons/DateInput";

import Input from "../Input";

const EditTaskModal = ({ operation, task, show, onSave, onCancel }) => {

  const [loading, setLoading] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const [title, setTitle] = useState(null);
  const [targetDate, setTargetDate] = useState(null);

  const today = moment().format("YYYY-MM-DD");

  useEffect(() => {
    if (show && task) {
      setTitle(task.title);

      if (operation === "edit") {
        setTargetDate(task.target_date);
      }
    }
  }, [show, operation, task]);

  const saveTask = async () => {
    setLoading(true);
    setTimeout(() => {
      resetState();
      onSave && onSave();
    }, 1500);
  }

  const resetState = () => {
    setTimeout(() => {
      setLoading(false);
      setCanSave(false);
      setTitle(null);
      setTargetDate(null);
    }, 1000);
  }

  const handleTitleChange = (event) => {
    event.preventDefault();
    setTitle(event.target.value);
    if (!canSave) { setCanSave(true); }
  }

  const handleTargetDateChange = (event) => {
    event.preventDefault();
    setTargetDate(event.target.value);
    if (!canSave) { setCanSave(true); }
  }

  const handleSaveClick = async () => {
    await saveTask();
  }

  const handleCancelClick = (event) => {
    event.preventDefault();
    resetState();
    onCancel && onCancel();
  }

  const renderModal = () => {
    return (
      <div className="pdp-edit-task-modal">
        <div className="title">
          {operation === "edit"
            ? I18n.t("pdp.modals.edit_task.title.edit")
            : I18n.t("pdp.modals.edit_task.title.create")
          }
        </div>
        <div className="subtitle">{I18n.t("pdp.modals.edit_task.subtitle")}</div>
        <Input
          label={I18n.t("pdp.modals.edit_task.inputs.title.label")}
          placeholder={I18n.t("pdp.modals.edit_task.inputs.title.placeholder")}
          value={title}
          onChange={handleTitleChange}
        />
        <DateInput
          min={today}
          label={I18n.t("pdp.modals.edit_task.inputs.target_date.label")}
          placeholder={I18n.t("pdp.modals.edit_task.inputs.target_date.placeholder")}
          value={targetDate}
          onChange={handleTargetDateChange}
        />
        <div className="buttons">
          <button
            className="cancel-btn"
            disabled={loading}
            onClick={handleCancelClick}
          >
            {I18n.t("common.cancel")}
          </button>
          <ActionButton
            filled
            loading={loading}
            disabled={!canSave}
            className="save-btn"
            title={I18n.t("pdp.modals.edit_task.buttons.save.title")}
            onClick={handleSaveClick}
          />
        </div>
      </div>
    );
  }

  return (
    <InternalModal
      type="edit-task"
      size="lg"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderModal()}
      showModalBody={false}
    />
  );
}

export default EditTaskModal;
