import React, { Component, Fragment, useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";

import useRoutingState from "../hooks/useRoutingState";

import ActionButton from "../commons/ActionButton";

import PdpSelector from "./components/PdpSelector";
import TeamMemberSelector from "./components/TeamMemberSelector";
import PlanTitle from "./components/PlanTitle";
import Timeline from "./components/Timeline";
import ObjectivesDetails from "./components/ObjectivesDetails";

import editIcon from "../assets/icons/edit.svg";



const tasks = [
  {
    index: 1,
    title: "Attend Concur Conference", 
    progress: 1.0, 
    completed_at: "2024-08-15T05:39:57+0000",
    status: "completed",
    history: [
      { title: "Progress updated to 100%", content: "I watched  30 mins of content on LinkedIn.", created_at: "2024-08-15T05:39:57+0000"},
      { title: "Progress updated to 15%", content: "I watched  30 mins of content on LinkedIn.", created_at: "2024-08-15T05:39:57+0000"},
      { title: "Task started", content: "I just started the task", created_at: "2024-08-15T05:39:57+0000"}
    ],
    note: {
      content: "[{\"type\":\"paragraph\",\"children\":[{\"text\":\"Some note!!!\"}]}]"
    }
  },
  {
    index: 2,
    title: "Read \"The Art of Persuasion\" book",
    progress: 0.45,
    target_date: "2024-08-15T05:39:57+0000",
    status: "in_progress",
    history: [
      { title: "Progress updated to 45%", content: "I watched  30 mins of content on LinkedIn.", created_at: "2024-08-15T05:39:57+0000"},
      { title: "Progress updated to 40%", content: "I watched  30 mins of content on LinkedIn.", created_at: "2024-08-15T05:39:57+0000"},
      { title: "Progress updated to 35%", content: "I watched  30 mins of content on LinkedIn.", created_at: "2024-08-15T05:39:57+0000"},
      { title: "Progress updated to 30%", content: "I watched  30 mins of content on LinkedIn.", created_at: "2024-08-15T05:39:57+0000"},
      { title: "Progress updated to 15%", content: "I watched  30 mins of content on LinkedIn.", created_at: "2024-08-15T05:39:57+0000"},
      { title: "Task started", content: "I just started the task", created_at: "2024-08-15T05:39:57+0000"}
    ],
    note: {
      content: "[{\"type\":\"paragraph\",\"children\":[{\"text\":\"Another note!!!\"}]}]"
    }
  },
  { index: 3, title: "Meet with Coach", progress: 0.0, target_date: "2024-08-15T05:39:57+0000", status: "not_started", history: []},
  { index: 4, title: "Attend Another Conference fdsfdsfdsfds fdsfdsf fdsfdsfs", progress: 0.0, target_date: "2024-08-15T05:39:57+0000", status: "not_started", history: []},

  //{ index: 5, title: "Attend Concur Conference", completed_at: "2024-08-15", status: "completed"},
  //{ index: 6, title: "Read \"The Art of Persuasion\" book", target_date: "2024-08-15", status: "in_progress"},
  //{ index: 7, title: "Meet with Coach", target_date: "2024-08-15", status: "not_started"},
  //{ index: 8, title: "Attend Another Conference fdsfdsfdsfds fdsfdsf fdsfdsfs", target_date: "2024-08-15", status: "not_started"},
  //{ index: 9, title: "Attend Concur Conference", completed_at: "2024-08-15", status: "completed"},
  //{ index: 10, title: "Read \"The Art of Persuasion\" book", target_date: "2024-08-15", status: "in_progress"},
  //{ index: 11, title: "Meet with Coach", target_date: "2024-08-15", status: "not_started"},
  //{ index: 12, title: "Attend Another Conference fdsfdsfdsfds fdsfdsf fdsfdsfs", target_date: "2024-08-15", status: "not_started"},
  //{ index: 13, title: "Attend Another Conference fdsfdsfdsfds fdsfdsf fdsfdsfs", target_date: "2024-08-15", status: "not_started"},
  //{ index: 14, title: "Attend Another Conference fdsfdsfdsfds fdsfdsf fdsfdsfs", target_date: "2024-08-15", status: "not_started"},
  //{ index: 15, title: "Attend Another Conference fdsfdsfdsfds fdsfdsf fdsfdsfs", target_date: "2024-08-15", status: "not_started"},
];

const defaultPlan = {
  title: "2024 Q2 Development Plan",
  created_at: "2024-05-12T05:39:57+0000",
  completed_at: "2024-08-12T05:39:57+0000",
  objectives: [
    { index: 1, title: "Become a master communicator", tasks: tasks, started_at: "2024-05-12T05:39:57+0000", completed_at: "2024-08-15T05:39:57+0000" },
    { index: 2, title: "Another awesome objective", tasks: tasks, started_at: "2024-06-13T05:39:57+0000", target_date: "2024-08-15T05:39:57+0000" },
    { index: 3, title: "Yet another objective I want to achieve", tasks: tasks, started_at: "2024-07-14T05:39:57+0000", target_date: "2024-08-15T05:39:57+0000" },
  ]
};

const Pdp = ({ teams, team, personal, isTeamLeadOrAbove }) => {
  useRoutingState();

  const [plan, setPlan] = useState(defaultPlan);
  const [objective, setObjective] = useState(null);

  useEffect(() => {
    setObjective(plan.objectives[2]);
  }, [plan]);

  const renderPageHeader = () => (
    <div className="pdp-row page-header">
      <div className="selectors">
        <PdpSelector
          teams={teams}
          selectedTeam={team}
          personal={personal}
          isTeamLeadOrAbove={isTeamLeadOrAbove}
        />
        <TeamMemberSelector team={team} />
        <PlanTitle title={plan.title} />
      </div>
      <div className="action-buttons">
        <ActionButton title="See Plan Summary" />
        <ActionButton title="Edit Plan" icon={editIcon} />
        <ActionButton title="Set Plan Complete" />
        <ActionButton title="See Past Plans" />
      </div>
    </div>
  );

  const renderPageContent = () => (
    <div className="page-content">
      <div className="objective-timeline">
        <span className="section-title">Timeline</span>
        {/* <Timeline
          start={objective?.started_at}
          end={objective?.completed_at || objective?.target_date}
          tasks={objective?.tasks}
        /> */}
      </div>
      <div className="objectives-panel">
        <span className="section-title">Objectives</span>
        <ObjectivesDetails
          objectives={plan.objectives}
          selectedObjective={objective}
          onObjectiveSelect={obj => { console.log("OBJ", obj); setObjective(obj)}}
        />
      </div>
    </div>
  );

  return (
    <Fragment>
      <div className="pdp">
        <div className="container-fluid">
          {renderPageHeader()}
          <div className="pdp-row">
            {renderPageContent()}
          </div>
        </div>
      </div>
      <Toaster
        position="top-right"
        reverseOrder
      />
    </Fragment>
  );
}

// NOTE: needed now to current version of ReactOnRails to work with hooks
export default class PdpComponent extends Component {
  render() {
    return <Pdp {...this.props} />;
  }
}