import React, { useState } from "react";

import Tabs from "../Tabs";
import ObjectiveInfo from "./ObjectiveInfo";
import TaskInfo from "./TaskInfo";

const ObjectivesDetails = ({ objectives, selectedObjective, onObjectiveSelect }) => {

  const [task, setTask] = useState(null);

  return (
    <div className="objectives-details">
      <Tabs
        selectedObjective={selectedObjective}
        objectives={objectives}
        onTabSelect={onObjectiveSelect}
      />
      <div className="details">
        <ObjectiveInfo
          objective={selectedObjective}
          onTaskSelect={setTask}
        />
        <TaskInfo task={task} />
      </div>
    </div>
  );
}

export default ObjectivesDetails;
