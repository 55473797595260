import React, { useState, useEffect } from "react";
import I18n from "i18n-js";

import Selector from "./Selector";

const TeamMemberSelector = ({ team, selectedMember, members }) => {
  //const member = selectedMember || members[0];

  //const [coachList, setCoachList] = useState([]);
  //const [coachProList, setCoachProList] = useState([]);

  //useEffect(() => {
  //  const coachList = teams.filter(team => !team.pro).map(team => ({
  //    title: team.name,
  //    url: getTeamPdpUrl(team),
  //  }));
//
  //  const coachProList = teams.filter(team => team.pro).map(team => ({
  //    title: team.name,
  //    url: getTeamPdpUrl(team),
  //  }));
//
  //  setCoachList(coachList);
  //  setCoachProList(coachProList);
  //}, [teams]);

  const getMemberPdpUrl = (member) =>
    `/teams/${team.uuid}/members/${member.uuid}/pdp?locale=${I18n.locale}`;

  return (
    <Selector
      className="team-member-selector"
      title={I18n.t("pdp.selector.my_plan")}
      items={[
        {
          title: I18n.t("pdp.selector.my_plan"),
          url: ""
        },
        {
          title: "John Smith",
          url: ""
        },
        {
          title: "Stacy Johnson",
          url: ""
        },
        {
          title: "Gabe Medina",
          url: ""
        },
        {
          title: "Cesar Keller",
          url: ""
        },
        {
          title: "Paul Kuc",
          url: ""
        }
      ]}
    />
  );
}

export default TeamMemberSelector;
