import React, { useEffect, useState } from "react";
import I18n from "i18n-js";

import InternalModal from "../../../commons/InternalModal";
import ActionButton from "../../../commons/ActionButton";
import Slider from "../../../commons/Slider";
import Textarea from "../../../commons/Textarea";

const UpdateTaskProgressModal = ({ task, show, onSave, onCancel }) => {

  const [loading, setLoading] = useState(false);
  const [canSave, setCanSave] = useState(false);

  const [progress, setProgress] = useState(0);
  const [description, setDescription] = useState(null);

  const progressPercent = task?.progress * 100;

  useEffect(() => {
    if (show && task) {
      setProgress(progressPercent);
    }
  }, [show, task]);

  const updateTaskProgress = async () => {
    setLoading(true);
    setTimeout(() => {
      resetState();
      onSave && onSave();
    }, 1500);
  }

  const resetState = () => {
    setTimeout(() => {
      setLoading(false);
      setCanSave(false);
      setProgress(0);
      setDescription(null);
    }, 1000);
  }

  const handleSlideChange = (_, value) => {
    setProgress(value);
    if (value > progressPercent && !canSave) {
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  }

  const handleDescriptionChange = (event) => {
    event.preventDefault();
    setDescription(event.target.value);
  }

  const handleSaveClick = async () => {
    await updateTaskProgress();
  }

  const handleCancelClick = (event) => {
    event.preventDefault();
    resetState();
    onCancel && onCancel();
  }

  const renderModal = () => {
    return (
      <div className="pdp-update-task-progress-modal">
        <div className="title">
          {I18n.t("pdp.modals.update_task_progress.title")}
        </div>
        <div className="subtitle">{I18n.t("pdp.modals.update_task_progress.subtitle")}</div>
        <div className="slider-wrapper">
          <Slider
            name="progress"
            value={progress}
            minValue={0}
            maxValue={100}
            onChange={handleSlideChange}
          />
        </div>
        <Textarea
          label={I18n.t("pdp.modals.update_task_progress.inputs.description.label")}
          placeholder={I18n.t("pdp.modals.update_task_progress.inputs.description.placeholder")}
          value={description}
          rows={5}
          onChange={handleDescriptionChange}
        />
        <div className="buttons">
          <button
            className="cancel-btn"
            disabled={loading}
            onClick={handleCancelClick}
          >
            {I18n.t("common.cancel")}
          </button>
          <ActionButton
            filled
            loading={loading}
            disabled={!canSave}
            className="save-btn"
            title={I18n.t("pdp.modals.update_task_progress.buttons.save.title")}
            onClick={handleSaveClick}
          />
        </div>
      </div>
    );
  }

  return (
    <InternalModal
      type="update-task-progress"
      size="lg"
      isModalOpen={show}
      hideModal={() => onCancel && onCancel()}
      component={renderModal()}
      showModalBody={false}
    />
  );
}

export default UpdateTaskProgressModal;
