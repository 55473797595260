import React from "react";
import moment from "moment";

const TaskHistoryItem = ({ item }) => {

  const formatDate = (date) => moment(date).format("L");

  return (
    <div className="task-history-item">
      <span className="title">{item.title}</span>
      <span className="content">{item.content}</span>
      <span className="date">{formatDate(item.created_at)}</span>
    </div>
  );
}

export default TaskHistoryItem;
